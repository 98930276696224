import GlobalStyles from './GlobalStyles';
import AppRouter from './AppRouter';
import Footer from './components/Footer';
import Header from './components/Header';


function App() {
  return (
    <>
 
    <Header />
    <AppRouter/>
    <Footer/>
    <GlobalStyles/>
    
    
    </>
  );
}

export default App;
